/* base */

html {
  font-size: 20px;
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 0.8rem;
  cursor: default;
}

img {
  border: 0 none;
  color: rgba(0,0,0,0.3);
  max-width: 100%;
}

iframe {
  display: block;
  border: 0 none;
}

button {
  cursor: pointer;
}

button[disabled] {
  opacity: 0.5;
}

a {
  color: #529d64;
  text-decoration: none;
}

p {
  margin: 0.5rem 0 1rem;
}

p:last-child {
  margin-bottom: 0.5rem;
}

p:only-child {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: 400;
  line-height: 1.25;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.67rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

::selection {
  background-color: #529d643f;
}

#root {
  min-height: 100%;
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#content {
  flex-grow: 1;
}

/* input */

.input-field {
  margin: 0;
  line-height: 1;
  font: inherit;
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 0.15rem;
}

.input-field {
  width: 10rem;
  padding: 0.25rem;
}

.input-field:focus {
  outline: 0 none;
}

.input-group .input-field {
  border-radius: 0;
}

.input-group .input-field:first-child {
  border-radius: 0.15rem 0 0  0.15rem;
}

.input-group .input-field:not(:first-child) {
  border-left-width: 0;
}

.input-group .input-field:last-child {
  border-radius: 0 0.15rem 0.15rem 0;
}

.input-field-narrow {
  width: 5rem;
}

.input-field-wide {
  width: 15rem;
  resize: vertical;
}

.input-radio {
  position: relative;
  display: inline-block;
  margin: 0.25rem;
  width: 1rem;
  height: 1rem;
  vertical-align: middle;
  appearance: none;
  cursor: pointer;
}

.input-radio::before,
.input-radio::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  border-radius: 50%;
}

.input-radio::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0.1rem solid rgba(0,0,0,0.2);
}

.input-radio::after {
  top: 20%;
  left: 20%;
  width: 60%;
  height: 60%;
  background-color: #529d64;
  opacity: 0;
  transform: scale(0);
  transition: opacity 0.3s ease, transform 0.3s ease-out;
}

.input-radio[checked]::after {
  opacity: 1;
  transform: scale(1);
}

.input-checkbox {
  position: relative;
  display: inline-block;
  margin: 0.25rem;
  width: 1rem;
  height: 1rem;
  vertical-align: middle;
  appearance: none;
  cursor: pointer;
}

.input-checkbox::before,
.input-checkbox::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
}

.input-checkbox::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0.1rem solid rgba(0,0,0,0.2);
  border-radius: 0.2rem;
}

.input-checkbox::after {
  top: 20%;
  left: 20%;
  width: 60%;
  height: 60%;
  background-color: #529d64;
  opacity: 0;
  transform: scale(0);
  transition: opacity 0.3s ease, transform 0.3s ease-out;
  border-radius: 0.1rem;
}

.input-checkbox[checked]::after {
  opacity: 1;
  transform: scale(1);
}

.input-label[for] {
  cursor: pointer;
}

.input-label {
  font-size: 1rem;
  user-select: none;
}

/* borders */

.rounded {
  border-radius: 0.5rem;
}

.circle {
  border-radius: 50%;
}

/* flex */

.flex {
  display: flex;
}

.align {
  align-items: center;
}

.column {
  flex-direction: column;
}

.wrap {
  flex-wrap: wrap;
}

.between {
  justify-content: space-between;
}

.around {
  justify-content: space-around;
}

.evenly {
  justify-content: space-evenly;
}

.center {
  justify-content: center;
}

.gap {
  gap: 1rem;
}

.grow {
  flex-grow: 1;
}

.ungrow {
  flex-grow: 0;
}

.shrink {
  flex-shrink: 1;
}

.unshrink {
  flex-shrink: 0;
}

.tiles {
  display: flex;
  flex-wrap: wrap;
  margin: -0.75rem;
}

.tiles .tile > * {
  height: 100%;
}

.tile {
  margin: 0.75rem;
}

.tile-3 {
  flex-basis: calc(100% / 3 - 0.75rem * 2);
}

/* grid */

.grid {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns:
    repeat( auto-fill, minmax( max( calc(50% - 0.75rem), 13rem), 1fr ) );
}

/* spacing */

.space-third {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.space-one {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.space-two {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.space-four {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.side-one {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

/* typo */

.bold {
  font-weight: 700;
}

/* menus */

.menu-inline {
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  flex-wrap: wrap;
}

.menu-inline li {
  display: inline-block;
}

.menu-inline li > a {

}

/* icons */

.icon {
  display: inline-block;
  background-position: center center;
  background-repeat: no-repeat;
  vertical-align: middle;
}

/* appear sequentially animation */

.fade-in {
  animation-name: fadein;
  animation-duration: 0.65s;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
}

@keyframes fadein {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.appear-seq {
  animation-name: appear;
  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
  animation-delay: calc(var(--animation-order, 0) * 25ms);
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: scale(0.8) translateY(-2rem);
  }
  100% {
    opacity: 1;
  }
}

/* buttons */

button {
  border: 0 none;
  font: inherit;
}

.button {
  display: inline-block;
  cursor: pointer;
}

a.button {
  text-decoration: none;
}

/* bg */

.bg-white {
  background-color: #fff;
  color: #212121;
}

.bg-lightgray {
  background-color: #F2F2F2;
}

.bg-gradient-top {
  color: #ffffff;
  background-image:
    linear-gradient(to bottom, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
}

.bg-gradient-bottom {
  color: #ffffff;
  background-image:
    linear-gradient(to top, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0) 100%);
}

.bg-gradient-left {
  color: #ffffff;
  background-image:
    linear-gradient(to right, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
}

/* adaptive */

/* wide */
@media (min-width: 961px) {
  .hide-wide {
    display: none;
  }
  .tile-3 {
    flex-basis: calc(100% / 3 - 0.75rem * 2);
  }
}

/* medium */
@media (min-width: 481px) and (max-width: 960px) {
  .hide-medium {
    display: none;
  }
  .tile-3 {
    flex-basis: calc(100% / 2 - 0.75rem * 2);
  }
}

/* narrow */
@media (max-width: 480px) {
  .hide-narrow {
    display: none;
  }
  .tile-3 {
    flex-basis: 100%;
  }

  h1 {
    font-size: 1.6rem;
  }

  h2 {
    font-size: 1.4rem;
  }

  h3 {
    font-size: 1.3rem;
  }

  h4 {
    font-size: 1.2rem;
  }

  h5 {
    font-size: 1.125rem;
  }

  h6 {
    font-size: 1rem;
  }
}

/* bagdes */

.badge {
  display: inline-block;
  padding: 0.1em 0.3em;
  color: #ffffff;
  background-color: rgba(0,0,0,0.5);
  border-radius: 0.2rem;
}

.Footer {
  color: #ffffff;
  background-color: #1D1D1D;
  padding-bottom: 1rem;
}

.Footer a {
  color: #ffffff;
  text-decoration: underline;
}

@media (max-width: 480px) {
  .Footer {
    font-size: 0.75rem;
  }
}

.print-only {
  display: none;
}

@media print {
  @page {
    margin: 0 1mm;
    size: 80mm 240mm;
    page-orientation: upright;
  }

  html { font-size: 5mm; }
  body {
    min-width: auto !important;
    font-family: sans-serif;
    line-height: 1rem;
  }

  p {
    margin: 0.25rem 0 !important;
  }

  .print-hide { display: none !important; }
  .print-only { display: initial; }

  .Header,
  .Footer,
  .WorkingTime {
    display: none;
  }

  .OrderSection-title {
    font-size: 1rem;
  }

  .OrderProduct-options {
    font-size: 0.8rem;
  }
}

.Manager {}

.Manager h2 {
  user-select: none;
}

.Manager input {
  font-size: 2rem;
}

.Manager-button {
  padding: 0 0.5rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 2rem;
  text-align: center;
  background-color: #f8a32c;
  border-radius: 0.5rem;
}
.WorkingTime {
  text-align: center;
  background-color: #ffe666;
}

@media (max-width: 480px) {
  .WorkingTime {
    font-size: 0.75rem;
  }
}
